import React, { useEffect, useState } from 'react'
import * as qs from 'query-string'

// compositions
import Layout from '@compositions/Layout'
import ThankYou from '@compositions/ThankYou'

const ThankYouPage = () => {
  const [layoutView, setLayoutView] = useState(false)
  const [postSlug, setPostSlug] = useState('')
  useEffect(() => {
    const { post } = qs.parse(window.location.search)
    if (post === undefined || post === '') {
      window.location.href = '/'
      return null
    }
    setLayoutView(true)
    setPostSlug(post)
  }, [layoutView])
  return layoutView ? (
    <Layout>
      <ThankYou post={postSlug} />
    </Layout>
  ) : null
}

export default ThankYouPage
