import { useStaticQuery, graphql } from 'gatsby'

export const thankYouContent = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const data = useStaticQuery(graphql`
    {
      wpgraphql {
        page(id: "678", idType: DATABASE_ID) {
          AllPageElements {
            pageelements {
              ... on WPGraphQL_Page_Allpageelements_Pageelements_Seodata {
                title
                description
                keyword
              }
            }
          }
          thankYouPageFields {
            downloadableContentPosts {
              # TODO: need to fix - this is causing server ereror
              # post {
              #   ... on WPGraphQL_Post {
              #     slug
              #   }
              # }
              pageLeftImageDesktop {
                altText
                sourceUrl
                mediaItemId
                modified
                imageFile {
                  childImageSharp {
                    fluid(maxWidth: 380, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
              pageLeftImageMobile {
                altText
                sourceUrl
                mediaItemId
                modified
                imageFile {
                  childImageSharp {
                    fluid(maxWidth: 660, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
              pageLogo {
                altText
                sourceUrl
                mediaItemId
                modified
                imageFile {
                  publicURL
                }
              }
              title
              description
              footerLink {
                text
                link
              }
            }
          }
        }
      }
    }
  `)

  return {
    seoData: data.wpgraphql.page.AllPageElements.pageelements[0],
    downloadablePosts:
      data.wpgraphql.page.thankYouPageFields.downloadableContentPosts
  }
}
