import React from 'react'
import styled from '@emotion/styled'
import { string } from 'prop-types'
import { Container, Row, Col } from 'react-bootstrap'
import BackgroundImage from 'gatsby-background-image'

// components
import SEO from '@components/SEO'
import { thankYouContent } from './modules/'

const ThankYou = styled(({ post, className }) => {
  const { seoData, downloadablePosts } = thankYouContent()

  return post !== undefined || post !== '' ? (
    <div className={`${className} row align-items-center`}>
      <SEO title={seoData.title} description={seoData.description} />
      {downloadablePosts.map((item, index) => {
        return post === item.post?.slug ? (
          <Container key={index}>
            <section className="thank-you">
              <Row>
                <Col sm="12" lg="4" className="p-lg-0">
                  <div className="d-lg-block d-none h-100">
                    <BackgroundImage
                      className="thank-you-background"
                      fluid={
                        item.pageLeftImageDesktop.imageFile.childImageSharp
                          .fluid
                      }
                    />
                  </div>
                  <div className="d-lg-none">
                    <BackgroundImage
                      className="thank-you-background"
                      fluid={
                        item.pageLeftImageMobile.imageFile.childImageSharp.fluid
                      }
                    />
                  </div>
                </Col>
                <Col sm="12" lg="8" className="p-lg-0">
                  <div className="thank-you-content">
                    <img
                      src={item.pageLogo.imageFile.publicURL}
                      className="logo"
                      alt={
                        item.pageLogo.altText
                          ? item.pageLogo.altText
                          : 'Thank You Logo'
                      }
                    />
                    <h1>{item.title}</h1>
                    <div
                      className="description"
                      dangerouslySetInnerHTML={{ __html: item.description }}
                    />
                    <a
                      href={item.footerLink.link}
                      className="thank-you-bottom-link"
                    >
                      {item.footerLink.text}
                    </a>
                  </div>
                </Col>
              </Row>
            </section>
          </Container>
        ) : null
      })}
    </div>
  ) : null
})`
  background: #fafafa;
  min-height: calc(100vh - 70px);

  .thank-you {
    background: #ffffff;
    box-shadow: 0px 25px 40px -10px rgba(112, 112, 112, 0.05);

    @media (max-width: 992px) {
      margin: 0 15px;
    }

    .thank-you-background {
      background-position: center top;
      background-size: cover;
      height: 100px;

      @media (min-width: 992px) {
        height: 100%;
      }
    }

    .thank-you-content {
      padding: 40px 20px 40px 20px;

      @media (min-width: 992px) {
        padding: 50px 129px 50px 125px;
      }

      .logo {
        height: 21px;
        margin-bottom: 40px;

        @media (min-width: 992px) {
          height: 34px;
          margin-bottom: 76px;
        }
      }

      h1 {
        color: #000000;
        font-family: Roboto;
        font-style: normal;
        font-weight: 900;
        font-size: 26px;
        line-height: 30px;
        font-size: 48px;
        line-height: 56px;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        font-feature-settings: 'pnum' on, 'lnum' on;
        margin-bottom: 30px;

        @media (min-width: 992px) {
          font-size: 48px;
          line-height: 56px;
        }
      }

      .description {
        margin-bottom: 60px;
        color: #767676;
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 32px;
        letter-spacing: 0.03em;
        font-feature-settings: 'pnum' on, 'lnum' on;

        @media (min-width: 992px) {
          margin-bottom: 114px;
          font-size: 24px;
          line-height: 36px;
        }
      }

      .thank-you-bottom-link {
        color: #2b58ca;
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 32px;
        letter-spacing: 0.03em;
        font-feature-settings: 'pnum' on, 'lnum' on;
      }
    }
  }
`

ThankYou.propTypes = {
  post: string.isRequired
}

ThankYou.defaultProps = {
  post: ''
}

export default ThankYou
